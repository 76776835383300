.root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.buttonGroup {
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    background-color: #ff6f61;
    padding: 10px 20px;
}

.button {
    border: none;
    border-radius: 50px;
    padding: 20px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    flex: 1;
    text-align: center;
}

.buttonUpcoming {
    background-color: white;
    color:black;
}
.buttonNotActive {
    background-color:#ff6f61;
    color:white;
}

.buttonPrevious {
background-color: white;
    color:black;
}

/* .buttonUpcoming:hover {
    background-color: #ff6f61;
    color: white;
}

.buttonPrevious:hover {
    background-color: #ff6f61;
        color: white;
} */