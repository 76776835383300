.carouselContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin: auto;
  min-height: 250px;
}
.ltrOnly {
  direction: ltr !important;
}

.carouselImages {
  display: flex;
  transition: transform 0.5s ease-in-out;
  .carouselContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    margin: auto;
    min-height: 250px;
    /* Ensure horizontal layout is consistent */
  }

  
  .carouselImages {
    display: flex;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .carouselImage img {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  }
  .carouselImagesText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 15%;
    text-align: center;
  }
  .carouselImagesText h1,
  .carouselImagesText p {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Shadow with black color and 70% opacity */
  }

  .carouselImagesText h1 {
    font-size: 26px;
    color: #f9be26;
    margin: 0px;
    /* background: rgba(255, 253, 233, 0.5); */
    padding: 10px;
    border-radius: 20px;
  }

  .carouselImagesText p {
    font-size: 8px;
    font-weight: 600;
    color: #000000;
    /* background: rgba(255, 253, 233, 0.5); */
    padding: 7px;
    border-radius: 20px;
  }

  html[lang="ar"] .carouselImagesText h1,
  html[lang="ar"] .carouselImagesText p {
    font-family: "arabic", sans-serif;
    direction: rtl;
  }

  html[lang="en"] .carouselImagesText h1,
  html[lang="en"] .carouselImagesText p {
    font-family: "english-medium", sans-serif;
    direction: ltr;
  }

  .carouselImage {
    min-width: 100%;
    box-sizing: border-box;
  }

  .carouselImage img {
    width: 100%;
    display: block;
  }

  .dotContainer {
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }

  .dot {
    height: 5px;
    width: 5px;
    margin: 10px 5px 0px 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  .dot.active {
    background-color: #717171;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
  }

  .shareIcon,
  .reviewIcon {
    display: flex;
    align-items: center;
    border-radius: 100%;
    padding: 7px 10px;
  }

  .shareIcon {
    border: 1px solid #2c72f8;
    cursor: pointer;
  }

  .reviewIcon {
    border: 1px solid #f26456;
  }

  .arrowContainer {
    display: flex;
    align-items: center;
    position: absolute;
  }
  .arrowIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .favoriteContainer {
    position: absolute;
  }

  .favoriteIcon {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 7px;
    border-radius: 10px;
  }

  /* Default positions for small screens */
  .buttonsContainer {
    bottom: 180px;
    left: 230px;
  }

  .arrowContainer {
    bottom: 185px;
    left: 20px;
  }

  .favoriteContainer {
    bottom: 50px;
    left: 250px;
  }
  @media screen and (min-width: 350px) {
    .buttonsContainer {
      bottom: 180px;
      left: 17rem;
    }

    .arrowContainer {
      bottom: 185px;
      left: 20px;
    }

    .favoriteContainer {
      bottom: 30px;
      left: 17rem;
    }
  }
  @media screen and (min-width: 400px) {
    .buttonsContainer {
      bottom: 10rem;
      left: 350px;
    }

    .arrowContainer {
      bottom: 10rem;
      left: 20px;
    }

    .favoriteContainer {
      bottom: 30px;
      left: 340px;
    }
  }
  /* Adjust positions for medium screens */
  @media screen and (min-width: 600px) {
    .buttonsContainer {
      bottom: 230px;
      left: 150px;
    }

    .arrowContainer {
      bottom: 230px;
      left: 50px;
    }

    .favoriteContainer {
      bottom: 30px;
      left: 150px;
    }
  }

  /* Adjust positions for large screens */
  @media screen and (min-width: 800px) {
    .buttonsContainer {
      bottom: 250px;
      left: 250px;
    }

    .arrowContainer {
      bottom: 250px;
      left: 80px;
    }

    .favoriteContainer {
      bottom: 30px;
      left: 300px;
    }
  }

  /* Adjust positions for extra large screens */
  @media screen and (min-width: 1000px) {
    .buttonsContainer {
      bottom: 300px;
      left: 400px;
    }

    .arrowContainer {
      bottom: 300px;
      left: 100px;
    }

    .favoriteContainer {
      bottom: 30px;
      left: 400px;
    }
  }
}

.carouselImage {
  min-width: 100%;
  box-sizing: border-box;
}

.carouselImage img {
  width: 100%;
  display: block;
}

.dotContainer {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.dot {
  height: 5px;
  width: 5px;
  margin: 10px 5px 0px 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
}

.shareIcon,
.reviewIcon {
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding: 7px 10px;
}

.shareIcon {
  border: 1px solid #2c72f8;
  cursor: pointer;
}

.reviewIcon {
  border: 1px solid #757f8d;
}

.arrowContainer {
  display: flex;
  align-items: center;
  position: absolute;
}

.favoriteContainer {
  position: absolute;
}

.favoriteIcon {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 7px;
  border-radius: 10px;
}

/* Default positions for small screens */
.buttonsContainer {
  bottom: 180px;
  left: 250px;
}

.arrowContainer {
  bottom: 185px;
  left: 20px;
}

.favoriteContainer {
  bottom: 50px;
  left: 250px;
}
@media screen and (min-width: 350px) {
  .buttonsContainer {
    bottom: 180px;
    left: 17rem;
  }

  .arrowContainer {
    bottom: 185px;
    left: 20px;
  }

  .favoriteContainer {
    bottom: 30px;
    left: 17rem;
  }
}
@media screen and (min-width: 400px) {
  .buttonsContainer {
    bottom: 10rem;
    left: 350px;
  }

  .arrowContainer {
    bottom: 10rem;
    left: 20px;
  }

  .favoriteContainer {
    bottom: 30px;
    left: 340px;
  }
}
/* Adjust positions for medium screens */
@media screen and (min-width: 600px) {
  .buttonsContainer {
    bottom: 230px;
    left: 150px;
  }

  .arrowContainer {
    bottom: 230px;
    left: 50px;
  }

  .favoriteContainer {
    bottom: 30px;
    left: 150px;
  }
}

/* Adjust positions for large screens */
@media screen and (min-width: 800px) {
  .buttonsContainer {
    bottom: 250px;
    left: 250px;
  }

  .arrowContainer {
    bottom: 250px;
    left: 80px;
  }

  .favoriteContainer {
    bottom: 30px;
    left: 300px;
  }
}

/* Adjust positions for extra large screens */
@media screen and (min-width: 1000px) {
  .buttonsContainer {
    bottom: 300px;
    left: 400px;
  }

  .arrowContainer {
    bottom: 300px;
    left: 100px;
  }

  .favoriteContainer {
    bottom: 30px;
    left: 400px;
  }
}
