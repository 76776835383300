.header {
  display: flex;
  gap: 15px;
  align-items: center;

  img {
    width: 150px;
    padding: 0 15px;
  }

  nav {
    padding-top: 5px;
    display: flex;
    gap: 15px;
    font-size: 20px;

    font-size: bold;

    a {
      font-weight: bold;
      color: #6862aa !important;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  .header {
    width: 390px;

    img {
      width: 70px;
      padding: 0;
    }

    nav {
      font-size: 14px;
    }
  }
}
