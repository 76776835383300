.section {
  width: 1400px;
  /* background-color: yellowgreen; */
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;

  h2 {
    color: #f44a31;
    text-align: center;
    font-size: 30px;
  }
}

.cover {
  width: 1400px;
  object-fit: cover;
}

.about {
  width: 1400px;
}

.owner {
  img {
    width: 1200px;
  }
}

.features {
  width: 1400px;
}

.featuresContainer,
.YallaFallaOffers {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 50px;
  font-size: 16px;
  margin-bottom: 100px;

  img {
    width: 150px;
  }

  p {
    color: #f44a31;
    font-weight: 600;
    width: 150px;
    text-align: center;
    margin: 0;
  }
}

.aboutContainer {
  background-color: #6862aa;
  color: #fff;
  font-size: 16px;
  text-align: end;

  border-radius: 15px;
  padding: 3rem;
  display: grid;
  align-content: start;
  grid-template-columns: 2fr 1fr;
  font-weight: 600;
  gap: 50px;

  img {
    width: 400px;
    border-radius: inherit;
  }
}

.owner {
  width: 1400px;
  img {
    width: 1400px;
  }
}

@media only screen and (max-width: 400px) {
  .section {
    width: 400px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;

    h2 {
      font-size: 20px;
      margin-top: 15px;
    }
  }

  .cover {
    width: 400px;
  }

  .features {
    width: 400px;

    text-align: center;
    color: #f44a31;
  }

  .featuresContainer,
  .YallaFallaOffers {
    gap: 8px;
    font-size: 9px;
    margin-bottom: 10px;
    img {
      width: 60px;
    }

    p {
      width: 70px;
    }
  }

  .about {
    width: 400px;
    h2 {
      text-align: center;
    }

    .aboutContainer {
      width: 375px;
      padding: 25px 8px;
      display: grid;
      gap: 20px;

      color: #fff;
      font-size: 12px;

      img {
        width: 150px;
        border-radius: 6px;
      }
    }
  }

  .content {
    height: 150px;
    overflow-y: scroll;
    

    p:first-child {
      margin-top: 0;
    }

   
  }

  .owner {
    width: 400px;
    img {
      width: 390px;
    }
  }

  .WhyYallaFalla {
    width: 390px;
    margin-bottom: 5rem;
  }
}
