/* src/components/RegistrationForm.module.css */
input:focus{
    border-color: #7DB8E2;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
select:focus {
    border-color: #7DB8E2;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
}

.formGroup {
    position: relative;
}
/* ar */
.formGroupAr {
    position: relative;
}

.formGroup label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #babfc6;
}
/* ar */
.formGroupAr label {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: black;
}


.formGroup input,
.formGroup select {
    font-family: 'english-medium';
    width: 100%;
    /* Set the same width for both */
    padding: 20px 40px;    /* Ensure padding is the same */
    border: 1px solid rgba(102, 112, 128, 0.3);
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
        /* Set height to ensure uniform input field size */
}
.formGroupAr input,
.formGroupAr select {
    font-family: 'arabic';
    width: 100%;
    /* Set the same width for both */
    padding: 20px 40px;
    /* Ensure padding is the same */
    border: 1px solid rgba(102, 112, 128, 0.3);
    border-radius: 50px;
    font-size: 16px;
    box-sizing: border-box;
    /* Ensures that padding is included in the width */
}

.formRow {
    display: flex;
    /* justify-content: center; */
    gap: 10px;
    width: 100%;
}

.formGroup input[type="number"] {
    -moz-appearance: textfield;
}
/* ar */
.formGroupAr input[type="number"] {
    -moz-appearance: textfield;
}

.formGroup input[type="number"]::-webkit-outer-spin-button,
.formGroup input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.submitButton {
    background-color: #F26557;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
}
.errorMessage {
    color: red;
    /* Change this to your desired color */
    font-size: 12px;
    /* Change this to your desired font size */
    margin-top: 5px;
    /* Adjust the space between the input and the error message */
}

/* styling the select and option menus */

.customSelect, .customSelectAr {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    font-size: 16px;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23000' d='M5 7L1 3h8z'/%3E%3C/svg%3E");
    background-position: left 10px top 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.customSelect option {
    font-size: 14px;
    padding: 12px;
    background-color: #fff;
    /* Background of the dropdown options */
    color: #333;
    /* Text color */
    border-bottom: 1px solid #f26557;
    /* Add a subtle bottom border */
}


@media (max-width: 600px) {
    .customSelect {
        padding: 8px;
        font-size: 14px;
        background-position: right 10px top 50%;
    }
    .customSelectAr {
        padding: 8px;
        font-size: 14px;
        background-position: left 8px top 50%;
    }

    .customSelect option {
        font-size: 12px;
        padding: 8px;
    }
}

/* Adjustments for medium-sized devices like tablets */
@media (min-width: 600px) and (max-width: 1200px) {
    .customSelect {
        padding: 10px;
        /* Standard padding for tablets */
        font-size: 15px;
        /* Slightly smaller font size */
        background-position: left 10px top 50%;
        /* Adjust background positioning */
    }

    .customSelect option {
        font-size: 14px;
        padding: 10px;
    }
}

/* Larger devices such as desktops */
@media (min-width: 1200px) {
    .customSelect {
        padding: 12px;
        /* Increase padding for larger screens */
        font-size: 16px;
        /* Larger font size for desktops */
        background-position: left 12px top 50%;
    }

    .customSelect option {
        font-size: 15px;
        padding: 12px;
    }
}
/* Add some spacing */
.custom-select-container {
    margin-bottom: 16px;
}