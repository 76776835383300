/* Container settings for responsive layout */
.carouselContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin: auto;
    height: auto;
    /* Set height to auto so it adjusts based on aspect ratio */
}

.ltrOnly {
    direction: ltr !important;
}

.carouselImages {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}

.carouselImage {
    flex: 0 0 100%;
    /* Ensure each image takes 100% of the container width */
    height: 0;
    padding-bottom: 56.25%;
    /* This maintains the 16:9 aspect ratio (16 / 9 * 100 = 56.25%) */
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}

.carouselImage img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the entire container while maintaining aspect ratio */
}

.carouselImagesText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 15%;
    text-align: center;
}

.carouselImagesText h1,
.carouselImagesText p {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carouselImagesText h1 {
    font-size: 26px;
    color: #f9be26;
    margin: 0;
    padding: 10px;
    border-radius: 20px;
}

.carouselImagesText p {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    padding: 7px;
    border-radius: 20px;
}

/* Dot pagination styles */
.dotContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 10px 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}

/* Button and icon positions */
.buttonsContainer,
.arrowContainer,
.favoriteContainer {
    position: absolute;
    display: flex;
    align-items: center;
}

.buttonsContainer,
.favoriteContainer {
    
    justify-content: center;
}

.arrowContainer {
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.shareIcon,
.reviewIcon {
    display: flex;
    align-items: center;
    border-radius: 100%;
    padding: 7px 10px;
}

.shareIcon {
    border: 1px solid #2c72f8;
    cursor: pointer;
}

.shareIconAlone {
    margin-left: 35px;
    display: flex;
    align-items: center;
    border-radius: 100%;
    padding: 7px 10px;
}

.reviewIcon {
    border: 1px solid #f26456;
}

.favoriteIcon {
    background-color: #ffffff;
    padding: 7px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Media queries for responsive layout */

@media screen and (max-width: 600px) {
    .buttonsContainer {
        top: 10px;
            /* Arrow icon in the top left */
        right: 10px;
        transform: none;
    }

    .arrowContainer {
        top: 10px;
        /* Arrow icon in the top left */
        left: 10px;
        transform: none;
    }

    .shareIcon {
        /* top: 10px; */
        /* Share icon in the top right */
        right: 50px;
        position: absolute;
        /* Ensure it's positioned absolutely like the arrow */
    }
    .qrIcon{
        right: 100px;
        position: absolute;
    }
    .cancelPageQrIcon{
        right:50px;
        position:absolute
    }

    .favoriteContainer {
        bottom: 10px;
        /* Favorite icon in the bottom right */
        right: 10px;
    }
}

@media screen and (min-width: 600px) {
    .buttonsContainer {
        bottom: 50px;
    }

    .favoriteContainer {
        left: 50%;
        transform: translateX(-50%);
    }

    .arrowContainer {
        left: 20px;
    }
}

@media screen and (min-width: 800px) {
    .buttonsContainer {
        bottom: 80px;
    }

    .favoriteContainer {
        left: 50%;
    }

    .arrowContainer {
        left: 30px;
    }
}

@media screen and (min-width: 1000px) {
    .buttonsContainer {
        bottom: 100px;
    }

    .favoriteContainer {
        left: 55%;
    }

    .arrowContainer {
        left: 40px;
    }
}