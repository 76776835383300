.section {
  width: 1400px;
  margin: 0 auto;
}

@media only screen and (max-width: 400px) {
  .section{
    width: 400px;
  }
}

