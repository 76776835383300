.snapshotContainer {
    display: flex;
    gap: 10px;
    padding: 10px 5px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-height: 8px;
}

.snapshotItem {
    width: 100vw;
    /* Adjust the width as necessary */
}

.snapshotContainer::-webkit-scrollbar {
    height: 8px;
}

.snapshotContainer::-webkit-scrollbar-thumb {
    /* background-color: gray;
    border-radius: 10px; */
}

.snapshotContainer::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
}
.snapshotImage{
    border-radius: 20px;
    width: 200px;
    height: 100%;
    object-fit: cover;
}

.dot {
    height: 6px;
    width: 6px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s;
}

.activeDot {
    background-color: #f26456;
    /* Active dot color */
}

.dot:hover {
    cursor: pointer;
}