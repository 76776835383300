.countryContainer {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-height: 8px;
}

.countryItem {
    width: 100vw;
    /* Adjust the width as necessary */
}

.countryContainer::-webkit-scrollbar {
    height: 8px;
}

.countryContainer::-webkit-scrollbar-thumb {
    /* background-color: gray;
    border-radius: 10px; */
}

.countryContainer::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
}